
import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import SearchSettings from "./components/SearchSettings";
import SampleExplore from "./components/SampleExplore";
import MobileSearchBar from "./components/MobileSearchBar";

const ExploreRecipes = () => {
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

  const status = useSelector((state) => state.atlas.status);
  const page = useSelector((state) => state.atlas.pageNum);


  // Check screen size on mount
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setIsFilterPanelOpen(false); // Hide on small screens
      } else {
        setIsFilterPanelOpen(true); // Show on large screens
      }
    };

    handleResize(); // Run on mount
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Toggle function for the filter panel
  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prev) => !prev);

  };

  return (
    <div className="relative flex bg-gray-100 h-full overflow-hidden">
      {/* Filter Panel */}
      <div
        className={`fixed z-40 h-screen bg-white transform ${
          isFilterPanelOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out lg:static lg:translate-x-0 lg:flex`}
      >
        <SearchSettings closePanel={toggleFilterPanel} />
      </div>

      {/*Content*/}
      {status === "loading" && page === 1 ? (
        <Loader />
      ) : (
        <div className="flex-1 h-full overflow-hidden p-2 md:p-3 pr-0 md:pr-0">
          <div className="fixed w-full pr-3 md:w-[85%] block lg:hidden z-10">
            <MobileSearchBar showFilters={toggleFilterPanel} />
          </div>
          <div className="h-full overflow-auto pt-14 lg:pt-0">
            <SampleExplore />
          </div>
        </div>
      )}
    </div>
  );
};

export default ExploreRecipes;
